import 'src/components/atoms/modal/index.scss';
import React, { useCallback, useEffect, useRef } from 'react';
import Icon from 'src/components/atoms/icon';
import { PAGES } from 'src/constants';
import { pushViewAllCloseEvent } from 'plugins/custom-plugin-adobe-launch';
import { useModal } from 'src/hooks';

export default () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isVisible = false, hideModal, innerContent } = useModal();
  const { HOME } = PAGES;

  const onMouseDown = useCallback(({ target }: MouseEvent) => {
    !ref?.current?.contains?.(target as Node) && hideModal();
  }, [ ref ]);

  const localHideModel = () => {
    let linkName = '';
    if (localStorage.getItem('ZipCodeModal')) {
      linkName = 'Close Zip Modal';
      localStorage.removeItem('ZipCodeModal');
    }

    if (sessionStorage.getItem('ViewDetailsModal')) {
      linkName = 'Close Plan Details Modal';
      sessionStorage.removeItem('ViewDetailsModal');
    }

    if (sessionStorage.getItem('ConfirmAddressModal')) {
      linkName = 'Close Address Confirm Modal';
      sessionStorage.removeItem('ConfirmAddressModal');
    }

    pushViewAllCloseEvent({
      event: 'linkClick',
      linkInfo:
      {
        linkAction: 'close',
        linkName: linkName,
        linkType: 'other',
        linkURL: HOME
      }
    });

    hideModal();
  };

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  let isFyxify: boolean = false;
  if (typeof window !== 'undefined') {
    isFyxify = sessionStorage.getItem('sps') === 'fyxify';
  }

  useEffect(() => {
    !isFyxify && isVisible
      ? window.addEventListener('mousedown', onMouseDown)
      : window.removeEventListener('mousedown', onMouseDown);

    document.body.classList.toggle('modal-open', isVisible);
  }, [ ref, isVisible ]);

  return (
    <div className='modal' style={{ visibility: isVisible ? 'visible' : 'hidden' }} ref={ref}>
      {!isFyxify ? <div className='modal--close'><Icon type='close' className='modal--close--icon' onClick={localHideModel} /></div> : null}
      <div className='modal--inner-content'>{innerContent}</div>
    </div>
  );
};
